<template>
  <el-dialog
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    append-to-body
    v-loading="loading"
    v-elDragDialog
    :title="$t('marketManagement.material')"
    width="850px"
    :visible.sync="show"
  >
    <div class="content" v-scroll="loadMore">
      <div class="info" v-for="(item, index) in list" :key="item.id">
        <img v-if="+item.type === 1 || item.isImage" v-viewer :src="item.sourcePath" />
        <video style="width:200px" v-else :src="item.sourcePath"></video>
        <div class="name">{{ item.name }}</div>
        <el-checkbox v-model="item.checked" />
        <div class="sort">
          <span>{{ $t('groupManagement.order') }}</span>
          <el-input
            size="mini"
            style="width:65px;margin-left:8px"
            v-model="item.sort"
            @blur="
              () => {
                !item.sort ? (item.sort = index + 1) : ''
              }
            "
            @input="
              val => {
                item.sort = onlyNum(val)
              }
            "
          />
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [dialogCommonParams, watchParamsGetData],
  components: {},
  props: {
    selectApp: {
      type: Array,
      default: () => []
    },
    operate: {
      type: String,
      default: ''
    },
    currentType: {
      type: Number
    }
  },
  data() {
    return {
      loading: false,
      params: {
        pageSize: 50,
        currentPage: 1
      },
      list: []
    }
  },
  async created() {
    this.getData()
  },
  methods: {
    blurHandler(value, index) {
      if (!value) {
        value = index
      }
    },
    loadMore() {
      ++this.params.currentPage
      this.getData()
    },
    async getData() {
      try {
        this.loading = true

        const type = this.currentType == 3 ? undefined : this.currentType

        const res = await this.$api.marketManagement.materialList({
          ...this.params,
          ...{ type }
        })
        const list = (res.result && res.result.list) || []

        list.forEach((item, index) => {
          item.checked = false
          item.sort = index + 1
        })

        this.list = this.params.currentPage === 1 ? list : this.list.concat(list)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    submit() {
      const data = this.list.filter(_ => _.checked)
      this.$emit('selectMaterial', data)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
}
.content {
  margin-top: 10px;
  overflow: auto;
  min-height: 256px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;

  .info {
    width: 230px;
    word-break: break-word;
    border: 1px solid #ccc;
    padding-top: 10px;
    margin-bottom: 5px;
    flex-shrink: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin-right: 10px;
    img {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    .name {
      font-size: 15px;
      font-weight: bold;
      margin: 10px 0;
      text-align: center;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .sort {
      margin-top: 20px;
    }
  }
}
</style>
