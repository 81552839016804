<template>
  <el-dialog
    v-elDragDialog
    v-loading="loading"
    width="800px"
    :title="currentItem ? $t('edit') : $t('newadd')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="addProgramModal"
  >
    <div class="mainWrap">
      <div v-if="materialList.length" class="leftPart">
        <div v-if="materialList[0].type === 'photo'" v-viewer>
          <img :src="materialList[0].path" />
        </div>
        <div v-else><video :src="materialList[0].path" controls></video></div>
      </div>

      <div class="rightPart">
        <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
          <template v-if="active === 0">
            <el-form-item :label="$t('marketManagement.name')" prop="name">
              <el-input v-model.trim="form.name" :placeholder="$t('marketManagement.enterName')" />
            </el-form-item>

            <el-form-item :label="$t('operateList.type')" prop="type">
              <el-select v-model="form.type">
                <el-option :label="$t('spaceDetails.photo')" value="photo" />
                <el-option :label="$t('spaceDetails.video')" value="video" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('marketManagement.direction')" prop="orient">
              <el-select v-model="form.orient">
                <el-option :label="$t('marketManagement.horizontalScreen')" value="horizontal" />
                <el-option :label="$t('marketManagement.verticalScreen')" value="vertival" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('marketManagement.rotationTime')" prop="loopTime">
              <el-input
                @input="
                  e => {
                    form.loopTime = form.loopTime.replace(/[^\d]/g, '')
                  }
                "
                v-model.trim="form.loopTime"
                :placeholder="$t('marketManagement.enterRotationTime')"
              />
            </el-form-item>

            <el-form-item :label="$t('marketManagement.material')" prop="materials">
              <div class="select" @click="selectMaterialVisible = true">
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
              <div v-if="materialList.length" class="materialList">
                <p v-for="(item, index) in materialList" :key="item.id">
                  <span> {{ item.appName }} {{ item.type === 'photo' ? '.png' : 'mp4' }} </span>
                  <i class="el-icon-close" @click="delHandler(index)"></i>
                </p>
              </div>
            </el-form-item>
          </template>
          <template v-if="active === 1">
            <el-form-item :label="$t('marketManagement.releaseTime')">
              <el-select v-model="inputType">
                <el-option :label="$t('loginPage.longTerm')" :value="0" />
                <el-option :label="$t('marketManagement.custom')" :value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="" v-if="inputType === 1">
              <el-date-picker
                v-model="form.inputDate"
                type="daterange"
                :range-separator="$t('to')"
                :start-placeholder="$t('startDate')"
                :end-placeholder="$t('endDate')"
              />
            </el-form-item>
            <el-form-item :label="$t('marketManagement.playFrequency')">
              <el-select v-model="form.playWay">
                <el-option
                  v-for="item in playWayList"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('marketManagement.playTime')">
              <el-select v-model="playType">
                <el-option :label="$t('marketManagement.allDay')" :value="0" />
                <el-option :label="$t('marketManagement.custom')" :value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="" v-if="playType === 1">
              <el-time-picker
                is-range
                v-model="form.playDate"
                format="HH:mm"
                :range-separator="$t('to')"
                :start-placeholder="$t('startDate')"
                :end-placeholder="$t('endDate')"
              />
            </el-form-item>
          </template>

          <template v-if="active === 2">
            <el-form-item :label="$t('publishApp.publishingMethod')">
              <el-select v-model="form.publishWay">
                <el-option
                  v-for="item in publishWayList"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                />
              </el-select>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <el-button v-if="active === 0" @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button v-if="active !== 0" type="primary" @click="pre">{{
        $t('publishApp.preStep')
      }}</el-button>
      <el-button
        v-if="active <= 2"
        type="primary"
        :disabled="active === 0 && (!materialList.length || !form.name)"
        @click="next"
        >{{ active === 2 ? $t('publishApp.publishNow') : $t('publishApp.nextStep') }}</el-button
      >
    </div>
    <select-material-modal
      v-if="selectMaterialVisible"
      :visible.sync="selectMaterialVisible"
      :operate="$t('publishApp.release')"
      @selectMaterial="renderData"
    />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import SelectMaterialModal from '../components/SelectMaterialModal'
export default {
  mixins: [dialogCommonParams],
  components: {
    SelectMaterialModal
  },
  data() {
    return {
      loading: false,
      selectMaterialVisible: false,
      active: 0,
      inputType: 0,
      playType: 0,
      form: {
        name: '',
        loopTime: 10,
        inputDate: '',
        playWay: 0,
        publishWay: 6,
        playDate: '',
        materials: [],
        type: 'photo',
        orient: 1
      },
      publishWayList: [
        {
          value: 6,
          label: this.$t('publishApp.fullRelease')
        },

        {
          value: 2,
          label: this.$t('publishApp.publishBySN')
        },
        {
          value: 5,
          label: this.$t('marketManagement.publishGroup')
        },
        {
          value: 3,
          label: this.$t('publishApp.publishDevice')
        }
      ],
      playWayList: [
        {
          label: this.$t('marketManagement.repeatEveryDay'),
          value: 0
        },
        {
          label: this.$t('marketManagement.Monday'),
          value: 2
        },
        {
          label: this.$t('marketManagement.Tuesday'),
          value: 3
        },
        {
          label: this.$t('marketManagement.Wednesday'),
          value: 4
        },
        {
          label: this.$t('marketManagement.Thursday'),
          value: 5
        },
        {
          label: this.$t('marketManagement.Friday'),
          value: 6
        },
        {
          label: this.$t('marketManagement.Saturday'),
          value: 7
        },
        {
          label: this.$t('marketManagement.Sunday'),
          value: 1
        }
      ],
      materialList: [],
      rules: {
        name: [{ required: true, message: this.$t('marketManagement.enterName') }],
        loopTime: [{ required: true, message: this.$t('marketManagement.enterRotationTime') }],
        materials: [{ required: true, message: this.$t('marketManagement.selectMaterial') }],
        type: [{ required: true }],
        orient: [{ required: true }]
      }
    }
  },
  created() {},
  methods: {
    renderData(data) {
      console.log(data)
      this.materialList = data
      this.form.materials = data
    },
    pre() {
      if (this.active-- < 0) this.active = 0
    },
    async next() {
      if (this.active === 2) {
        try {
          await this.submit()
        } catch (e) {
          console.log(e)
        }
      } else {
        this.active++
      }
    },
    async submit() {
      console.log(this.form)

      // try {
      //   this.loading = true
      //   const res = await this.$api.appManagement.publishPost(this.form)
      //   this.$message({
      //     message: '发布成功',
      //     type: 'success',
      //     duration: 5000
      //   })

      //   this.show = false
      // } catch (err) {
      // } finally {
      //   this.loading = false
      // }
    },
    delHandler(index) {
      this.materialList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.addProgramModal {
  .mainWrap {
    display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
    .leftPart {
      width: 50%;
      height: 245px;

      div {
        width: 100%;
        height: 100%;
      }

      img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      video {
        width: 100%;
        height: 100%;
      }
    }

    .rightPart {
      padding: 0 20px;

      .select {
        width: 100%;
        margin-bottom: 10px;
        height: 50px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px dashed #d9d9d9;
        &:hover {
          border-color: #074d29;
        }
        .el-icon-plus {
          font-size: 40px;
          color: #ccc;
        }
        span {
          font-size: 12px;
        }
      }

      .materialList {
        display: flex;
        flex-direction: column;
        max-height: 120px;
        overflow-y: auto;
        p {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          line-height: 20px;

          .el-icon-close {
            position: static;
            font-size: 16px;
          }
        }
      }
    }
  }
  .footer {
    padding: 20px 0 10px;
    display: flex;
    justify-content: center;
  }
}
</style>
