<template>
  <el-dialog
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    append-to-body
    v-loading="loading"
    v-elDragDialog
    :title="$t('marketManagement.material')"
    :visible.sync="show"
  >
    <div class="content" v-scroll="loadMore">
      <div class="info" v-for="item in list" :key="item.id">
        <img v-viewer :src="item.iconPath" />
        <div class="name">{{ item.appName }}</div>
        <el-checkbox v-model="item.checked" />
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [dialogCommonParams, watchParamsGetData],
  components: {},
  props: {
    selectApp: {
      type: Array,
      default: () => []
    },
    operate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      params: {
        pageSize: 50,
        currentPage: 1,
        appName: ''
      },
      list: []
    }
  },
  async created() {
    this.getData()
  },
  methods: {
    loadMore() {
      ++this.params.currentPage
      this.getData()
    },
    async getData() {
      try {
        this.loading = true
        const res = await this.$api.appManagement.appList(this.params)
        const list = (res.result && res.result.list) || []

        list.forEach(item => {
          item.checked = false
          item.type = 'photo'
          item.path = item.iconPath
        })

        this.list = this.params.currentPage === 1 ? list : this.list.concat(list)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    submit() {
      const data = this.list.filter(_ => _.checked)
      this.$emit('selectMaterial', data)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
}
.content {
  margin-top: 10px;
  overflow: auto;
  min-height: 256px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;

  .info {
    width: 200px;
    word-break: break-word;
    border: 1px solid #ccc;
    padding-top: 10px;
    margin-bottom: 5px;
    flex-shrink: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
    margin-right: 10px;
    img {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    .name {
      font-size: 15px;
      font-weight: bold;
      margin: 10px 0;
      text-align: center;
    }
  }
}
</style>
