<template>
  <el-dialog
    v-elDragDialog
    v-loading="loading"
    width="800px"
    :title="$t('details')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="show"
    class="materialDetail"
  >
    <div class="mainWrap">
      <div class="leftPart">
        <div v-if="+currentItem.type === 1" v-viewer>
          <img :src="currentItem.sourcePath" />
        </div>
        <div v-else><video :src="currentItem.sourcePath" controls></video></div>
      </div>

      <div class="rightPart">
        <h3>{{$t('marketManagement.basicInfo')}}</h3>
        <p>
          <span class="label">{{$t('marketManagement.name')}}: </span>
          <span>
            {{ currentItem.name }}
          </span>
        </p>
        <p>
          <span class="label">{{$t('marketManagement.type')}}: </span>
          <span>
            {{ currentItem.type === 1 ?$t('spaceDetails.photo'):$t('spaceDetails.video')}}
          </span>
        </p>
        <p>
          <span class="label">{{$t('marketManagement.format')}}: </span>
          <span>
            {{ currentItem.format }}
          </span>
        </p>
        <p v-if="currentItem.type === 1">
          <span class="label">{{$t('marketManagement.measurement')}}: </span>
          <span>
            {{ currentItem.resolution }}
          </span>
        </p>
        <p>
          <span class="label">{{$t('marketManagement.size')}}: </span>
          <span>
            {{ currentItem.size }}
          </span>
        </p>
      </div>
    </div>
    <div class="footer">
      <!-- <el-button size="small" type="primary" @click="downLoadHandler">下载到本地</el-button> -->
    </div>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      loading: false,
      appImagesourcePath: []
    }
  },

  methods: {
    async downLoadHandler() {
      try {
        this.loading = true

        const res = await this.$api.marketManagement.downloadMaterial({ id: this.currentItem.id })

        if (+this.currentItem.type === 1) {
          window.open(res.result, '_self')
        } else {
          this.$utils.downloadFile(res.result)
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.materialDetail {
  .mainWrap {
    display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
    .leftPart {
      width: 50%;
      height: 245px;

      div {
        width: 100%;
        height: 100%;
      }

      img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      video {
        width: 100%;
        height: 100%;
      }
    }

    .rightPart {
      padding: 0 20px;
      h3 {
        margin-bottom: 30px;
      }
      p {
        align-items: center;
        margin-bottom: 20px;
        .label {
          width: 60px;
          margin-right: 10px;
          flex-shrink: 0;
        }
      }
    }
  }
  .footer {
    padding: 20px 0 10px;
    display: flex;
    justify-content: center;
  }
}
</style>
