<template>
  <page v-loading="loading" :borderBottom="true">
    <div slot="headerLeft">
      <span class="spanItem active">{{ $t('details') }}</span>
    </div>
    <div slot="headerRight" class="flex" style="align-items: center;">
      <el-button size="small" type="primary" @click="$router.go(-1)">{{
        $t('backList')
      }}</el-button>
    </div>

    <div class="mainWrap">
      <div class="row">
        <span class="label">{{ $t('publishApp.materialPublish') }}:</span>
        <div class="content contentImg" v-if="materialList.length">
          <el-carousel trigger="click" height="245px" :autoplay="false">
            <el-carousel-item v-for="a in materialList" :key="a.id">
              <img :src="a.sourcePath" v-if="a.type === 1" />
              <video :src="a.sourcePath" controls v-else></video>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="row">
        <span class="label">{{ $t('publishApp.publishingMethod') }}:</span>
        <div class="content">
          <span style="margin-right:20px;">{{ currentItem.publishWayStr }}</span>
        </div>
      </div>
      <div class="row">
        <span class="label">{{ $t('operateList.associatedDevices') }}:</span>
        <span>{{ currentItem.count || 0 }}{{ $t('adminHomePage.tower') }}</span>
        <el-link @click="downloadList" type="primary">{{
          $t('operateList.downloadDeviceList')
        }}</el-link>
      </div>
    </div>

    <div class="bottomWrap">
      <device-view ref="DeviceView" :faterItem="currentItem" />
    </div>
  </page>
</template>

<script>
import DeviceView from './components/DeviceView'

export default {
  components: {
    DeviceView
  },
  data() {
    return {
      loading: false,
      materialList: []
    }
  },
  computed: {
    currentItem() {
      let data = sessionStorage.getItem('currentAppDetail')
      data = data ? JSON.parse(data) : {}
      return data
    }
  },
  mounted() {
    this.getImgList()
  },
  methods: {
    async getImgList() {
      if (this.currentItem?.sourceIds) {
        try {
          let materialList = []
          let sourceId = this.currentItem.sourceIds.split(',')
          const res = await this.$api.marketManagement.materialList({
            currentPage: 1,
            pageSize: 5000
          })
          const list = (res.result && res.result.list) || []
          sourceId.forEach(id => {
            let item = list.find(_ => _.id === +id)
            item && materialList.push(item)
          })
          this.materialList = materialList
        } catch (e) {
          console.log(e)
        }
      }
    },
    async downloadList() {
      const loading = this.$loading()
      try {
        let params = { ...(this.$refs.DeviceView?.params || {}) }
        if (params.status === '') delete params.status
        const res = await this.$api.marketManagement.downloadList(params)
        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          loading.close()
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.contentImg {
  width: 350px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.el-button {
  padding: 6px 15px;
}

.mainWrap {
  .itemInfo {
    min-width: 200px;
    width: auto;
  }
  .row {
    display: flex;

    & + .row {
      margin-top: 18px;
      display: flex;
      align-items: center;
    }
    .label {
      width: 120px;
      flex-shrink: 0;
    }
    .row {
      flex-wrap: wrap;
      margin-top: 10px;
      span + span {
        margin-left: 2px;
      }
    }
  }
  ::v-deep .el-link {
    margin-left: 5px;
  }
}

.bottomWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20px;
  .switchHead {
    display: flex;
    justify-content: center;
  }
}
</style>
