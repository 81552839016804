<template>
  <el-dialog
    v-elDragDialog
    width="550px"
    :title="
      currentItem ? $t('marketManagement.editMaterial') : $t('marketManagement.uploadMaterial')
    "
    :close-on-click-modal="false"
    :visible.sync="show"
    :before-close="close"
    class="add-material"
  >
    <el-form
      v-loading="loading"
      :element-loading-text="uploadMessage"
      :model="form"
      size="small"
      ref="form"
      label-width="100px"
    >
      <el-form-item
        :rules="[{ required: true, message: $t('deviceModal.pleaseUploadFile') }]"
        :label="$t('uploadFiles')"
        prop="file"
      >
        <el-upload
          action="null"
          :on-change="onFilechange"
          :on-remove="handleRemove"
          :show-file-list="true"
          :limit="100"
          :multiple="true"
          accept=".png,.jpg,.gif,.mp4,.rmvb,.wmv"
          :auto-upload="false"
          ref="fileUpload"
        >
          <el-button @click="$refs.fileUpload.clearFiles()" type="primary">{{
            $t('deviceModal.selectFile')
          }}</el-button>
        </el-upload>
      </el-form-item>

      <p class="tips">{{ $t('marketManagement.materialTips') }}</p>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('cancel') }}</el-button>
      <el-button :disabled="loading === true" type="primary" @click="submitForm(form)">{{
        $t('save')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import axios from 'axios'
import { PLATFORM } from '@/lib/constant'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      loading: false,
      uploadMessage: 0,
      form: {
        file: ''
      }
    }
  },
  created() {
    console.log(this.currentItem)
  },
  methods: {
    close() {
      this.show = false
      this.uploadMessage = 0
      if (this.cancels) {
        this.cancels()
      }
    },

    onFilechange(file, fileList) {
      const limit = file.size / 1024 / 1024 / 1024 < 1

      if (!limit) {
        this.$message({
          message: this.$t('marketManagement.materialTips1'),
          type: 'warning'
        })
        fileList.length = fileList.length - 1
      } else {
        if (fileList.length > 1) {
          const haveVideo = fileList.some(
            _ =>
              _.raw.type.includes('mp4') ||
              _.raw.type.includes('rmvb') ||
              _.raw.type.includes('wmv')
          )

          if (haveVideo) {
            this.$message({
              message: this.$t('marketManagement.videoOnly'),
              type: 'warning'
            })

            fileList.length = fileList.filter(
              _ =>
                !_.raw.type.includes('mp4') &&
                !_.raw.type.includes('rmvb') &&
                !_.raw.type.includes('wmv')
            ).length
          }
        }
      }

      this.form.file = fileList.map(_ => _.raw)
    },

    handleRemove(file, fileList) {
      this.form.file = fileList.map(_ => _.raw)
    },

    submitForm(data) {
      const CancelToken = axios.CancelToken
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          const formData = new FormData()

          for (const i in this.form) {
            const formItemV = this.form[i]
            if (formItemV) {
              formData.append(i, formItemV)
              if (i === 'file') {
                formData.append('parameter1', formItemV.name)
              }
            }
          }

          try {
            const apiType = PLATFORM === 'admin' ? '/admin/api' : '/sp/api'

            let url = `/scpserver${apiType}/publish/fileTransfer`

            if (process.env.NODE_ENV === 'development') {
              url = url.replace('/scpserver', '')
            }
            let locale = ''
            if (this.$i18n.locale == 'en') {
              locale = 'en_US'
            } else if (this.$i18n.locale == 'ruRU') {
              locale = 'ru_RU'
            } else {
              locale = 'zh_CN'
            }

            const res = await axios.post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                system: PLATFORM === 'admin' ? 4 : 5,
                'Accept-Language': locale
              },
              timeout: 20000000,
              cancelToken: new CancelToken(c => {
                this.cancels = c
              }),
              onUploadProgress: progressEvent => {
                if (progressEvent && progressEvent.loaded) {
                  let complete = (((progressEvent.loaded / progressEvent.total) * 100) | 0) + '%'
                  this.uploadMessage = complete
                }
              }
            })

            if (res.data.code !== 'OK') {
              this.$message({
                message: res.data.summary || this.$t('systemError'),
                type: 'error',
                duration: 3 * 1000
              })
            } else {
              this.$message.success(this.$t('deviceModal.transferSuccess'))
              this.show = false
            }
          } catch (e) {
            console.log(e)
            this.$message({
              message: res.data.summary || this.$t('systemError'),
              type: 'error',
              duration: 3 * 1000
            })
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-material {
  ::v-deep .el-loading-spinner i {
    font-size: 30px;
  }

  ::v-deep .el-loading-spinner .el-loading-text {
    color: #85d71a;
  }

  ::v-deep .el-upload-list {
    max-height: 320px;
    overflow-y: auto;
  }

  .tips {
    font-size: 13px;
    color: #666;
    margin-left: 30px;
    margin-bottom: 20px;
  }
}
</style>
