<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('deviceList.sn')"
        v-model.trim="params.sn"
        @keyup.enter.native="replaceData"
        clearable
      />
      <el-select v-model="params.status" clearable>
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" @click="replaceData" plain>{{ $t('query') }}</el-button>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column
          min-width="150"
          show-overflow-tooltip
          prop="sn"
          :label="$t('deviceList.sn')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('deviceList.deviceType')"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="200"
          prop="spName"
          :label="$t('publishOta.boundCustomer')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="statusName"
          :label="$t('operateList.pushStatus')"
        />
        <el-table-column show-overflow-tooltip prop="remark" :label="$t('deviceType.remark')" />
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'

export default {
  props: ['faterItem'],
  mixins: [watchParamsGetData],
  data() {
    return {
      detailVisible: false,
      firstIn: true,
      currentItem: null,
      statusOptions: [
        {
          label: this.$t('operateList.notPushed'),
          value: 0
        },
        {
          label: this.$t('operateList.alreadyPushed'),
          value: 8
        },
        {
          label: this.$t('operateList.pushSuccee'),
          value: 1
        },
        {
          label: this.$t('operateList.pushFailed'),
          value: 9
        },
        {
          label: this.$t('operateList.downloaded'),
          value: 2
        }
      ],
      tableData: [],
      statusList: {
        0: this.$t('operateList.notPushed'),
        1: this.$t('operateList.pushSuccee'),
        2: this.$t('operateList.downloaded'),
        3: this.$t('operateList.alreadyInstalled'),
        4: this.$t('operateList.run'),
        5: this.$t('operateList.uninstalled'),
        6: this.$t('operateList.noApp'),
        8: this.$t('operateList.alreadyPushed'),
        9: this.$t('operateList.pushFailed'),
        10: this.$t('operateList.installFail')
      },
      params: {
        publishId: this.faterItem.id
      }
    }
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.status === '') delete params.status
        const res = await this.$api.marketManagement.pageDeviceByPublishId(params)
        if (res && res.result) {
          this.tableData = (res.result.list || []).map(item => {
            item.statusName = this.statusList[item.status]
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
img {
  width: 60px;
  height: 60px;
}
.table-wrap {
  min-height: 230px;
  .row {
    display: flex;

    flex-wrap: wrap;
    img {
      margin-right: 10px;
    }
    & > div {
      border: 1px solid #ccc;
      margin: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .name {
        font-size: 15px;
        font-weight: bold;
        margin: 10px 0;
      }

      & + div {
        margin-left: 10px;
      }
    }
  }
}
</style>
