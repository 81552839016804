import { render, staticRenderFns } from "./AddMaterialModal.vue?vue&type=template&id=00e74dcf&scoped=true"
import script from "./AddMaterialModal.vue?vue&type=script&lang=js"
export * from "./AddMaterialModal.vue?vue&type=script&lang=js"
import style0 from "./AddMaterialModal.vue?vue&type=style&index=0&id=00e74dcf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e74dcf",
  null
  
)

export default component.exports