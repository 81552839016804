<template>
  <el-dialog
    v-elDragDialog
    v-loading="loading"
    width="800px"
    :title="$t('details')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="show"
    class="materialDetail"
  >
    <div class="mainWrap">
      <div class="leftPart">
        <div v-if="currentItem.type === 'photo'" v-viewer>
          <img :src="currentItem.path" />
        </div>
        <div v-else><video :src="currentItem.path" controls></video></div>
      </div>

      <div class="rightPart">
        <h3>{{$t('marketManagement.basicInfo')}}</h3>
        <p>
          <span class="label">{{$t('marketManagement.name')}}: </span>
          <span>
            {{ currentItem.name }}
          </span>
        </p>
        <p>
          <span class="label">{{$t('operateList.type')}}: </span>
          <span>
            {{ currentItem.type }}
          </span>
        </p>
        <p>
          <span class="label">{{$t('marketManagement.format')}}: </span>
          <span>
            {{ currentItem.format }}
          </span>
        </p>
        <p>
          <span class="label">{{$t('marketManagement.measurement')}}: </span>
          <span>
            {{ currentItem.ratio }}
          </span>
        </p>
        <p>
          <span class="label">{{$t('marketManagement.size')}}: </span>
          <span>
            {{ currentItem.size }}
          </span>
        </p>
      </div>
    </div>
    <!-- <div class="footer">
      <el-button size="small" type="primary" @click="downLoad">下载到本地</el-button>
    </div> -->
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      loading: false,
      appImagePath: [],
      item: {}
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      this.item = {
        // path:
        //   'https://scp-app-folder.oss-cn-shenzhen.aliyuncs.com/20210310120142298950067.jpg?Expires=1618454770&OSSAccessKeyId=LTAI4FpHNe9F6gHUCvBuDJBH&Signature=cYHWCmkZM3egCb9CHmJYtLZifig%3D',
        // type: 'photo',
        path: './test.mp4',
        type: 'video',
        name: '2021春季促销单品',
        format: 'PNG',
        size: '200kb',
        ratio: '1920*1080'
      }

      // try {
      //   this.loading = true
      //   const res = await this.$api.appManagement.appGet({ appId: this.appInfo.appId })
      //   this.item = res.result
      // } catch (err) {
      // } finally {
      //   this.loading = false
      // }
    },
    async downLoad() {
      try {
        this.loading = true
        let formData = new FormData()
        formData.append('appId', this.appInfo.appId)

        const res = await this.$api.appManagement.getAppUrl(formData)

        window.open(res.result, '_self')
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.materialDetail {
  .mainWrap {
    display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
    .leftPart {
      width: 50%;
      height: 245px;

      div {
        width: 100%;
        height: 100%;
      }

      img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      video {
        width: 100%;
        height: 100%;
      }
    }

    .rightPart {
      padding: 0 20px;
      h3 {
        margin-bottom: 30px;
      }
      p {
        align-items: center;
        margin-bottom: 20px;
        .label {
          width: 60px;
          margin-right: 10px;
          flex-shrink: 0;
        }
      }
    }
  }
  .footer {
    padding: 20px 0 10px;
    display: flex;
    justify-content: center;
  }
}
</style>
