<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('marketManagement.materialName')"
        v-model.trim="params.name"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>
    <div slot="headerRight">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="
          currentItem = null
          visible = true
        "
        >{{ $t('marketManagement.uploadMaterial') }}</el-button
      >
    </div>

    <div class="mainCot" v-loading="loading" v-scroll="loadmore">
      <template v-if="materialList.length">
        <div
          @click="detailHandler(item)"
          class="listItem"
          v-for="item in materialList"
          :key="item.id"
        >
          <el-card>
            <div class="main">
              <img v-if="+item.type === 1" :src="item.sourcePath" />
              <video v-else @click.stop :src="item.sourcePath"></video>
            </div>

            <div class="el-card-bottom">
              <div>
                <el-tag v-if="+item.type === 1" type="primary">{{
                  $t('spaceDetails.photo')
                }}</el-tag>
                <el-tag v-else type="success ">{{ $t('spaceDetails.video') }}</el-tag>
              </div>

              <div style="z-index:100">
                <i class="el-icon-delete" @click.stop="delHandler(item)"></i>
              </div>
            </div>
            <div class="modal"></div>
          </el-card>

          <div class="bottom-wrap" @click.stop>
            <el-input
              v-if="item.edit"
              @blur="
                val => {
                  blurHandler(item)
                }
              "
              v-model="item.name"
              size="small"
            />
            <span v-else class="label">{{ item.name }}</span>
            <i class="el-icon-edit" @click.stop="updateHandler(item)"></i>
          </div>
        </div>
      </template>
      <p class="no-data" v-else>{{ $t('noData') }}</p>
    </div>

    <add-material-modal
      @getData="replaceData"
      :visible.sync="visible"
      :current-item="currentItem"
      v-if="visible"
    />
    <material-detail-modal :current-item="currentItem" :visible.sync="visible2" v-if="visible2" />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddMaterialModal from './components/AddMaterialModal'
import MaterialDetailModal from './components/MaterialDetailModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddMaterialModal,
    MaterialDetailModal
  },
  data() {
    return {
      visible: false,
      visible2: false,
      materialList: [],
      currentItem: null,
      noMore: false
    }
  },
  methods: {
    async blurHandler(item) {
      try {
        await this.$api.marketManagement.updateMaterial({
          name: item.name,
          id: item.id
        })
        item.edit = !item.edit
      } catch (e) {
        console.log(e)
      }
    },
    loadmore() {
      if (this.total < this.params.pageSize) {
        return
      }
      ++this.params.currentPage
      this.getData()
    },
    detailHandler(item) {
      this.visible2 = true
      this.currentItem = item
    },
    async delHandler(row) {
      try {
        await this.$confirm(this.$t('appList.deleteApp'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
        this.loading = true

        await this.$api.marketManagement.deleteMaterial({ id: row.id })
        this.$message.success(this.$t('deleteSuccess'))
        this.getData(false)
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    updateHandler(item) {
      item.edit = true
      this.currentItem = item
    },
    async getData() {
      // this.materialList = [
      //   {
      //     id: 1,
      //     sourcePath:
      //       'https://scp-app-folder.oss-cn-shenzhen.aliyuncs.com/20210310120142298950067.jpg?Expires=1618454770&OSSAccessKeyId=LTAI4FpHNe9F6gHUCvBuDJBH&Signature=cYHWCmkZM3egCb9CHmJYtLZifig%3D',
      //     type: 'photo',
      //     name: '2021春季促销单品',
      //     format: 'PNG',
      //     size: '200kb',
      //     resolution: '1920*1080'
      //   },
      //   {
      //     id: 2,
      //     sourcePath: './test.mp4',
      //     type: 'video',
      //     name: '2021春季促销单品',
      //     format: 'MP4',
      //     size: '200kb',
      //     resolution: '1920*1080'
      //   }
      // ]

      try {
        if (this.noMore && this.params.currentPage !== 1) return

        this.loading = true

        const params = { ...this.params }

        if (params.type == 3) {
          delete params.type
        }

        const res = await this.$api.marketManagement.materialList(this.params)

        if (res.result) {
          const list = (res.result.list || []).map(item => {
            item.size = this.$utils.bytesToSize(parseInt(item.sourceSize * 1024))
            item.edit = false
            return item
          })
          this.materialList = this.params.currentPage === 1 ? list : this.materialList.concat(list)
          if (!list.length && this.params.currentPage !== 1) this.noMore = true
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mainCot {
  position: relative;
  min-height: 400px;
  overflow: auto;
  height: 0;
  flex: 1;
  .listItem {
    width: 30%;
    display: inline-block;
    margin-right: 28px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;

    &:hover {
      .el-card-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      & > .el-card .modal {
        display: block;
      }
    }

    .el-card {
      position: relative;

      .modal {
        background: rgba(81, 81, 81, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: none;
      }
    }

    ::v-deep .el-card__body {
      display: flex;
      flex-direction: column;

      .main {
        display: flex;
        margin-bottom: 20px;
        height: 100px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      video {
        width: 100%;
      }
    }
    .label {
      margin-top: 10px;
      font-weight: bold;
    }
  }
  .el-card-bottom {
    text-align: right;
    position: absolute;
    width: calc(100% - 40px);
    bottom: 10px;
    display: none;

    i {
      font-size: 22px;
      margin: 0 4px;
      color: #074d29;
    }
  }

  .bottom-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 18px;
      margin: 8px 4px 0;
      color: #074d29;
    }
  }
}
</style>
